<template>
  <div class="container">
    <div class="top" id="bloc-0">
      <voice-header></voice-header>
    </div>
    <div class="main" style="min-height: 600px">
      <div class="main_title" style="min-height: 100px;">
        <div>
          <H1 class="main_title_p01"><span>{{ $t('upload.main_title_p01') }}</span></H1>
        </div>
        <div>
          <H2 class="main_title_p02"><span>{{ $t('upload.main_title_p02') }}</span></H2>
        </div>
      </div>

      <div class="main_div">
        <div class="main_div_upload">
          <div class="upload-container" 
               @dragover.prevent 
               @drop.prevent="handleDrop"
               :class="{ 'dragging': isDragging }">
            
            <div class="upload-area">
              <input 
                type="file" 
                ref="fileInput" 
                @change="handleFileSelect" 
                accept="audio/*"
                style="display: none"
              />
              <div class="upload-content">
                <i class="upload-icon">↑</i>
                <p class="upload-text">{{ $t('upload.drag_drop') }}</p>
                <p class="upload-text-sub">{{ $t('upload.or') }}</p>
                <button 
                  class="upload-button"
                  @click="triggerFileInput"
                  :disabled="isUploading">
                  {{ $t('upload.browse_files') }}
                </button>
              </div>
            </div>

            <div v-if="selectedFile" class="file-info">
              <div class="file-details">
                <span class="file-name">{{ selectedFile.name }}</span>
                <span class="file-size">({{ formatFileSize(selectedFile.size) }})</span>
              </div>
              <button 
                class="upload-submit-button"
                @click="handleUpload"
                :class="{ 'loading': isUploading }"
                :disabled="isUploading">
                <span v-if="!isUploading">{{ $t('upload.start_upload') }}</span>
                <span v-else class="loading-spinner"></span>
              </button>
            </div>
          </div>

          <div class="upload-restrictions">
            <h3>{{ $t('upload.restrictions_title') }}</h3>
            <ul>
              <li>{{ $t('upload.max_size') }}</li>
              <li>{{ $t('upload.supported_formats') }}</li>
              <li>{{ $t('upload.max_duration') }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="footer" id="bloc-10">
      <div>
        <voice-footer></voice-footer>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
import VoiceHeader from '@/components/VoiceHeader.vue'
import { reportError } from '../utils/errorReporter'
import { trackAction } from '../utils/actionReporter'

const STATIC_CONFIG = Object.freeze({
  api_host: 'https://tiktokvoice.net',
  max_file_size: 10 * 1024 * 1024, // 10MB
  supported_formats: ['audio/mpeg', 'audio/wav', 'audio/mp3', 'audio/x-m4a']
})

export default {
  name: 'upload',
  components: {
    VoiceHeader,
    VoiceFooter: defineAsyncComponent({
      loader: () => import('../components/VoiceFooterIndex.vue'),
      timeout: 2000
    })
  },

  data() {
    return {
      selectedFile: null,
      isDragging: false,
      isUploading: false,
      uploadProgress: 0
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'currentUser', 'authToken'])
  },

  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click()
    },

    handleDrop(e) {
      this.isDragging = false
      const files = e.dataTransfer.files
      if (files.length) {
        this.validateAndSetFile(files[0])
      }
    },

    handleFileSelect(e) {
      const files = e.target.files
      if (files.length) {
        this.validateAndSetFile(files[0])
      }
    },

    validateAndSetFile(file) {
      if (!STATIC_CONFIG.supported_formats.includes(file.type)) {
        alert(this.$t('upload.invalid_format'))
        return
      }

      if (file.size > STATIC_CONFIG.max_file_size) {
        alert(this.$t('upload.file_too_large'))
        return
      }

      this.selectedFile = file
    },

    formatFileSize(bytes) {
      if (bytes === 0) return '0 B'
      const k = 1024
      const sizes = ['B', 'KB', 'MB', 'GB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
    },

    async handleUpload() {
      if (!this.selectedFile || this.isUploading) return

      if (!this.isLoggedIn) {
        alert(this.$t('upload.login_required'))
        return
      }

      this.isUploading = true

      try {
        const formData = new FormData()
        formData.append('file', this.selectedFile)
        formData.append('email', this.currentUser.email)

        const response = await fetch(`${STATIC_CONFIG.api_host}/api/upload`, {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': `Bearer ${this.authToken}`
          }
        })

        const data = await response.json()

        if (data.success) {
          alert(this.$t('upload.success'))
          this.selectedFile = null
          trackAction({
            email: this.currentUser.email,
            action: 'upload-success',
            domain: 'tiktokvoice.net'
          })
        } else {
          throw new Error(data.message || 'Upload failed')
        }
      } catch (error) {
        reportError(error, 'handleUpload')
        alert(this.$t('upload.error'))
      } finally {
        this.isUploading = false
      }
    }
  }
}
</script>

<style scoped>
.main_div_upload {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.upload-container {
  border: 2px dashed #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
}

.upload-container.dragging {
  border-color: #4CAF50;
  background-color: rgba(76, 175, 80, 0.1);
}

.upload-area {
  padding: 40px 20px;
}

.upload-icon {
  font-size: 48px;
  color: #666;
}

.upload-text {
  margin: 15px 0;
  font-size: 18px;
  color: #666;
}

.upload-text-sub {
  margin: 10px 0;
  color: #999;
}

.upload-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: #45a049;
}

.upload-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.file-info {
  margin-top: 20px;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.file-details {
  margin-bottom: 10px;
}

.file-name {
  font-weight: bold;
  margin-right: 10px;
}

.file-size {
  color: #666;
}

.upload-submit-button {
  padding: 10px 30px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-submit-button:hover {
  background-color: #1976D2;
}

.upload-submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.upload-restrictions {
  margin-top: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.upload-restrictions h3 {
  margin-bottom: 15px;
  color: #333;
}

.upload-restrictions ul {
  list-style-type: none;
  padding: 0;
}

.upload-restrictions li {
  margin: 10px 0;
  color: #666;
}

.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>